import './style.css';

import {MIDDLE_SECTION} from '../../Module/General';

function LogoSectionAbout() {
    return (
        <div className="LogoSectionAbout">
            <h1>{MIDDLE_SECTION.TITLE}</h1>
            <p>{MIDDLE_SECTION.LONG_DESCRIPTION}</p>
        </div>
    );
}

function Logo() {
    return (
        <div className="logoS">
            <img className="Logo" src={MIDDLE_SECTION.LOGO} alt="TLEHacks"/>
        </div>
    );
}

export {Logo, LogoSectionAbout};
