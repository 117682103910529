import {Fragment} from 'react';
export const MdxContent = () => {
    return (
        <Fragment>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
            <h1>cgdsjhgfchds</h1>
        </Fragment>
    );
};
